import React from "react";
import { useDispatch } from "react-redux";
import { setItemInLocalStorage } from "../helpers/localstorageHelper";

const ActivePlan = ({ activePlan }) => {
    const dispatch = useDispatch();

    const handleStep = () => {
        setItemInLocalStorage("currentStep", 3);
        dispatch({
            type: "STEP_CURRENT",
            payload: 3,
        });
    };

    return (
        <div className="border-2 px-4 py-4 active-plan">
            <h2>
                {activePlan.selectedProductId === 1
                    ? "Aktiv"
                    : activePlan.selectedProductId === 2
                    ? "Komfort"
                    : "Premium"}
            </h2>
            <div className="flex flex-col items-center justify-center active-plan__price">
                <div className="flex items-center justify-center ">
                <div>
                    <p>{activePlan.euros}</p>
                </div>
                <div className="flex flex-col active-plan__cent text-sm">
                    <div>{activePlan.cents}</div>
                    <div>
                    €
                    {activePlan.paymentIntervalMonths === 1
                        ? " monatlich."
                        : " jährl."}
                    </div>
                </div>
                </div>
                <p className="text-center !max-w-max">Du zahlst {activePlan.paymentIntervalMonths === 1 ? "monatlich." : "jährl."}</p>
            </div>
            <button className="btn" onClick={handleStep}>
                Tarifinformation
            </button>
        </div>
    );
};

export default ActivePlan;
