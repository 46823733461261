import React from "react";
import { useSelector } from "react-redux";
// import { CheckIcon } from "@heroicons/react/20/solid";
import Logo from "../Logo";
import tick from '../../assets/img/tick.svg'

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const Header = ({ className }) => {
    const steps = useSelector((state) => state.step);

    return (
        <div className={className + ' header xl:flex justify-center'}>
            <div className="header__logo">
                <Logo />
            </div>
            <nav aria-label="Progress" className="header__nav flex justify-center">
                {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                <ol role="list" className="flex justify-center header__list">
                    {steps.map((step, stepIdx) => (
                        <li
                            key={step.name}
                            className={classNames(
                                "relative header__step",
                                step.status === "complete" ? 'header__step--complate' : '',
                                step.status === "current" ? 'header__step--current' : ''
                            )}
                        >
                                <div className="flex header__circle justify-center items-center">
                                   <img width="18" height="18" src={tick} alt="tick"/>
                                </div>

                                {steps.length - 1 !== stepIdx && (
                                    <span className="line"></span>
                                )}

                                <span className="sr-only">
                                    {step.name}
                                </span>
                                <span className="header__step-name">
                                    {step.name}
                                </span>
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    );
};

export default Header;
