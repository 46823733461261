import React from "react";
import { TfiDownload } from "react-icons/tfi";

const DownloadFile = ({ file, linkText }) => {
    return (
        <div className="download">
            <a href={file} target="_blank" rel="noreferrer" className="mt-7 border-1 border-primary_pink rounded-lg text-primary_pink py-2 px-4 flex items-center justify-center w-fit">
                {linkText}
                <span>
                    <TfiDownload className="w-[24px] h-[24px] inline ml-[8px] sm:ml-[16px]" />
                </span>
            </a>
        </div>
    );
};

export default DownloadFile;
