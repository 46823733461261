import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getItemFromLocalStorage, setItemInLocalStorage} from "../../../helpers/localstorageHelper";
import { CiEdit } from "react-icons/ci";
import Button from "../../ui/Button";
import Accordion from "../../ui/Accordion";
import DownloadFile from "../../DownloadFile";

import ActivePlanTotal from "../../ActivePlanTotal";
import axios from "axios";
import {apiRoutes} from "../../../utils/apiRoutes";
const Step6 = (props) => {

    const dispatch = useDispatch();

    const [activePlan] = useState(
        useSelector((state) => state.form.productIdActive)
    );
    const [resErrors, setResErrors] = useState("")
    const form = useSelector((state) => state.form);

    const [documents, setDocuments] = useState([])

    async function getDocuments() {
        try {
            const response = await axios.get(apiRoutes.documents);
            return response.data.documents
        } catch (error) {
            throw new Error("Failed to retrieve data");
        }
    }

    async function createContract(params) {
        try {
            const response = await axios.post(apiRoutes.contracts, params);
            return response.data
        } catch (error) {
            throw error.response.data;
        }
    }

    const formattedRegistrationDate = form.registrationDate
    const formattedBirthdayDate = form.birthday

    const submitForm = (step) => {
        setIsSubmitLoading(true)
        let tariffName = ''
        switch (activePlan.selectedProductId ){
            case 1:
                tariffName = 'aktiv'
                break;
            case 2:
                tariffName = 'komfort'
                break;
            default:
                tariffName = 'premium'
                break;
        }

        const params = {
            "version": "v1",
            "contracts": [
                {
                    "version": "v1",
                    "key": "repko",
                    "payload": {
                        "tariff": tariffName,
                        "identification": {
                            "vin": form.vin,
                            "hsn": form.selectedCar.hsn,
                            "tsn": form.selectedCar.tsn,
                            "manufacturer": form.selectedCar.manufacturer,
                            "category": form.selectedCar.category.toLowerCase(),
                            "modelGroup": form.selectedCar.modelGroup.toLowerCase(),
                            "model": form.selectedCar.model,
                            "fuelType": form.selectedCar.fuelType.toLowerCase(),
                            "ps": form.selectedCar.ps,
                            "cylinder": form.selectedCar.cylinder,
                            "doors": parseInt(form.selectedCar.doors),
                            "kw": form.selectedCar.kw
                        },
                        "registration": {
                            "licensePlate": form.licensePlatePartOne + '-' + form.licensePlatePartTwo + '-' + form.licensePlatePartThree,
                            "initialRegistrationDate": formattedRegistrationDate,
                            "policyholderRegistrationDate": formattedRegistrationDate
                        },
                        "condition": {
                            "annualMileage": parseInt(form.yearlyDistance),
                            "totalMileage": parseInt(form.kilometresDriven)
                        },
                        "address": {
                            "houseNumber": form.streetNumber,
                            "street": form.street,
                            "city": form.city,
                            "zipcode": form.zipCode
                        },
                        "contract": {
                            "paymentFrequency": form.productIdActive.paymentIntervalMonths + "m",
                            "paymentDetails": {
                                "iban": form.iban,
                                "invoice": form.isInvoiceRecipient
                            }
                        }
                    }
                }
            ],
            "user": {
                "firstName": form.firstName,
                "lastName": form.lastName,
                "birthdate": formattedBirthdayDate,
                "email": form.email,
                "newsletter": form.newsletter,
                "phoneNumber": form.phone ? form.countryCode + form.phone :'',
                "address": {
                    "houseNumber": form.streetNumber,
                    "street": form.street,
                    "city": form.city,
                    "zipcode": form.zipCode
                }
            }
        }

        createContract(params)
            .then((data) => {
                if(data.contracts[0].id){
                    setIsSubmitLoading(false)
                    const currentFormData = getItemFromLocalStorage("form") || {};
                    const updatedFormData = {
                        ...currentFormData,
                        contractId : data.contracts[0].id
                    };
                    setItemInLocalStorage("form", updatedFormData);
                    setItemInLocalStorage("currentStep", +step);
                    updateStep(step)
                }
            })
            .catch((error) => {
                if(error){
                    if(error.error.includes('initialRegistrationDate')){
                        const errorMessage = 'Erstzulassung ist nicht gültig'
                        setResErrors(errorMessage)
                    } else {
                        setResErrors(error.error)
                    }
                }
                setIsSubmitLoading(false)
            })
    }

    const updateStep = (step) => {
        setItemInLocalStorage("currentStep", +step);
        dispatch({
            type: "STEP_CURRENT",
            payload: +step,
        });
    };

    const goToStep = (stepId) => {
        setItemInLocalStorage("currentStep", stepId);
        dispatch({
            type: "STEP_CURRENT",
            payload: stepId,
        });
    };

    const ibanBicOnly = form.isAccountOwner && form.paymentMethod === "sepa";
    const ibanBicStep5 = !form.isAccountOwner && form.paymentMethod === "sepa";

    const [checkboxFirst, setCheckboxFirst] = useState(false);
    const [checkboxSecond, setCheckboxSecond] = useState(false);
    const [newsletter, setNewsletterCheckbox] = useState(useSelector((state) => state.form.newsletter));
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    useEffect(() => {
        const currentFormData = getItemFromLocalStorage("form") || {};
        const updatedFormData = {
            ...currentFormData,
            newsletter,
        };
        setItemInLocalStorage("form", updatedFormData);
        dispatch({
            type: "FORM_UPDATE",
            payload: {
                newsletter: newsletter
            },
        });
    }, [dispatch, form.newsletter, newsletter])

    useEffect(() => {
        if (checkboxFirst && checkboxSecond) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
    }, [checkboxFirst, checkboxSecond]);

    useEffect(()=> {
        getDocuments()
            .then((data) => {
                setDocuments(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    return (
        <div className="">
            <div className="text-center max-w-[518px] m-auto">
                <h1 className=" font-bold ">
                    Alles auf einen Blick
                </h1>
                <p>
                    Hier siehst du alle Angaben, die du zu deiner gewählten Reparaturkostenversicherung gemacht hast. Falls dir ein Fehler auffällt kannst du ihn nun korrigieren.
                </p>
            </div>
            <div className="mt-5 text-sm sm:mt-10 rounded-lg border-2 text-[#313130] leading-normal border-[#FBDD05] sm:text-center py-5 px-2.5">
                Die Reparaturkostenversicherung ist ein reines Zusatzprodukt - diese ersetzt nicht deine KFZ-Versicherung.
            </div>
            <div className="from-section__flex lg:flex justify-between items-start mt-[20px] sm:mt-[60px]">
                <div className="form-section__accordions lg:w-[42.5%]">
                    <div className="accordion-wrapper">
                        <Accordion title={"Gewählter Schutz"}>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-bold">
                                        Fahrleistung in km/Jahr
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="km/Jahr" className="mr-2">
                                            <button
                                                onClick={() => goToStep(1)}
                                                id="km/Jahr"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.yearlyDistance}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                        </Accordion>
                    </div>
                    <div className="accordion-wrapper">
                        <Accordion title={"Auto und Halter"}>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-bold">
                                        Hersteller
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Hersteller" className="mr-2">
                                            <button
                                                onClick={() => goToStep(2)}
                                                id="Hersteller"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.selectedCar.manufacturer}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-bold">
                                        Automodell
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Automodell" className="mr-2">
                                            <button
                                                onClick={() => goToStep(2)}
                                                id="Automodell"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.selectedCar.modelGroup}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-lg font-bold">
                                        Erstzulassung
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Erstzulassung" className="mr-2">
                                            <button
                                                onClick={() => goToStep(1)}
                                                id="Erstzulassung"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={formattedRegistrationDate.split("-").reverse().join(".")}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                        </Accordion>
                    </div>
                    <div className="accordion-wrapper">
                        <Accordion title={"Persönliche Daten"}>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">Vorname</h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Vorname" className="mr-2">
                                            <button
                                                onClick={() => goToStep(0)}
                                                id="Vorname"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.firstName}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">Nachname</h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Nachname" className="mr-2">
                                            <button
                                                onClick={() => goToStep(0)}
                                                id="Nachname"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.lastName}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">Straße</h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Straße" className="mr-2">
                                            <button
                                                onClick={() => goToStep(4)}
                                                id="Straße"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.street}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">
                                        Hausnummer
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Hausnummer" className="mr-2">
                                            <button
                                                onClick={() => goToStep(4)}
                                                id="Hausnummer"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.streetNumber}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">
                                        Postleitzahl
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Postleitzahl" className="mr-2">
                                            <button
                                                onClick={() => goToStep(4)}
                                                id="Postleitzahl"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.zipCode}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">Stadt</h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Stadt" className="mr-2">
                                            <button
                                                onClick={() => goToStep(4)}
                                                id="Stadt"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.city}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">
                                        Geburtsdatum
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Geburtsdatum" className="mr-2">
                                            <button
                                                onClick={() => goToStep(4)}
                                                id="Geburtsdatum"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={formattedBirthdayDate.split("-").reverse().join(".")}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">
                                        Telefonnummer
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Telefonnummer" className="mr-2">
                                            <button
                                                onClick={() => goToStep(4)}
                                                id="Telefonnummer"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.countryCode + form.phone}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">E-Mail</h3>
                                    <div className="flex items-center">
                                        <label htmlFor="email" className="mr-2">
                                            <button
                                                onClick={() => goToStep(4)}
                                                id="email"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={form.email}
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                        </Accordion>
                    </div>
                    <div className="accordion-wrapper">
                        <Accordion title={"Zahlungsdaten"}>
                            <div className="accordion__content">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-blod">
                                        Zahlungsweise
                                    </h3>
                                    <div className="flex items-center">
                                        <label htmlFor="Zahlungsweise" className="mr-2">
                                            <button
                                                onClick={() => goToStep(5)}
                                                id="Zahlungsweise"
                                                className="px-2 py-1 inline rounded"
                                            >
                                                Anpassen{" "}
                                                <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={
                                        form.paymentMethod === "sepa"
                                            ? "IBAN"
                                            : "Rechnung"
                                    }
                                    disabled
                                    className="border p-2 w-full"
                                />
                            </div>
                            {(ibanBicOnly || ibanBicStep5) && (
                                <div>
                                    <div className="accordion__content">
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-base font-blod">
                                                IBAN
                                            </h3>
                                            <div className="flex items-center">
                                                <label htmlFor="IBAN" className="mr-2">
                                                    <button
                                                        onClick={() => goToStep(5)}
                                                        id="IBAN"
                                                        className="px-2 py-1 inline rounded"
                                                    >
                                                        Anpassen{" "}
                                                        <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                                    </button>
                                                </label>
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            value={form.iban}
                                            disabled
                                            className="border p-2 w-full"
                                        />
                                    </div>
                                </div>
                            )}
                        </Accordion>
                    </div>
                </div>
                <div className="form-section__aside-bar lg:w-[42.5%]">
                    <div>
                    <div className="flex justify-between items-center form-section__editer">
                        <h3 className="text-base font-blod">Versicherung:</h3>
                        <div className="flex items-center">
                            <label htmlFor="Versicherung" className="mr-2">
                                <button
                                    onClick={() => goToStep(3)}
                                    id="Versicherung"
                                    className="px-2 py-1 inline rounded"
                                >
                                    Anpassen{" "}
                                    <CiEdit className="w-[24px] h-[24px] inline mr-[16px]" />
                                </button>
                            </label>
                        </div>
                    </div>
                    <ActivePlanTotal activePlan={activePlan} />
                </div>
                <div className="form-section__wr">
                    <h2>Rechtliches</h2>
                    <p className="text-sm">
                        Bitte lade dir diese Dokumente herunter und speichere sie ab
                    </p>
                    {documents && (
                        <div>
                            {documents.map((document, i) => (
                                <DownloadFile
                                    key={i}
                                    file={document.url}
                                    linkText={document.filename}
                                />
                             ))
                            }
                        </div>
                    )}
                </div>
                <div>
                    <div className="field field--checkbox">
                            <input
                                id="checkboxFirst"
                                name="checkboxFirst"
                                type="checkbox"
                                onChange={(e) => setCheckboxFirst(e.target.checked)}
                                checked={checkboxFirst}
                                className="sr-only"
                            />
                        <label
                            htmlFor="checkboxFirst"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            <p>
                                {checkboxFirst ? (
                                    <svg width="24" height="24" className="w-[24px] h-[24px] inline mr-[16px] active"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1.5" y="1.5" width="21" height="21" rx="5.5" stroke="#D900B5"/>
                                    <path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="#D900B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                ) : (
                                    <svg width="24" height="24" className="w-[24px] h-[24px] inline mr-[16px] "  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1.5" y="1.5" width="21" height="21" rx="5.5" stroke="#D900B5"/>
                                    <path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="#D900B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                )}
                                Ich bestätige, dass ich die genannten Dokumente
                                gelesen und gespeichert oder ausgedruckt habe.
                            </p>
                        </label>
                    </div>
                    <div className="field field--checkbox">
                            <input
                                id="checkboxSecond"
                                name="checkboxSecond"
                                type="checkbox"
                                onChange={(e) =>
                                    setCheckboxSecond(e.target.checked)
                                }
                                checked={checkboxSecond}
                                className="sr-only"
                            />
                        <label
                            htmlFor="checkboxSecond"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            <p>
                                {checkboxSecond ? (
                                    <svg width="24" height="24" className="w-[24px] h-[24px] inline mr-[16px] active"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1.5" y="1.5" width="21" height="21" rx="5.5" stroke="#D900B5"/>
                                    <path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="#D900B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                ) : (
                                    <svg width="24" height="24" className="w-[24px] h-[24px] inline mr-[16px] "  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1.5" y="1.5" width="21" height="21" rx="5.5" stroke="#D900B5"/>
                                    <path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="#D900B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                )}
                                Ich habe diese Zusammenfassung gelesen und bin damit
                                einverstanden
                            </p>
                        </label>
                    </div>
                    <div className="field not-required field--checkbox">
                            <input
                                id="newsletterCheckbox"
                                name="newsletterCheckbox"
                                type="checkbox"
                                onChange={(e) =>
                                    setNewsletterCheckbox(e.target.checked)
                                }
                                checked={newsletter}
                                className="sr-only"
                            />
                        <label
                            htmlFor="newsletterCheckbox"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            <p>
                                {newsletter ? (
                                    <svg width="24" height="24" className="w-[24px] h-[24px] inline mr-[16px] active"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1.5" y="1.5" width="21" height="21" rx="5.5" stroke="#D900B5"/>
                                    <path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="#D900B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                ) : (
                                    <svg width="24" height="24" className="w-[24px] h-[24px] inline mr-[16px] "  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1.5" y="1.5" width="21" height="21" rx="5.5" stroke="#D900B5"/>
                                    <path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="#D900B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                )}
                                Abonniere unseren Newsletter
                            </p>
                        </label>
                    </div>
                </div>
                <div className="form-section__buttons">
                    <Button onClick={() => {
                            updateStep(props.step - 1)
                        }} className={"btn btn-secondary"}>
                        Zurück
                    </Button>
                    <Button
                        onClick={() => {
                            submitForm(props.step + 1)
                        }}
                        className={"btn mt-4 btn-primary"}
                        isLoading={isSubmitLoading}
                        disabled={btnDisabled || isSubmitLoading}
                    >
                        Jetzt kostenpflichtig beantragen
                    </Button>
                </div>
                    {resErrors && (
                        <div className="text-[#ef4444] mt-3">{resErrors}</div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default Step6;
