import React, { useEffect } from "react";
import successForm from "../../../assets/img/success-form.png";
import {gtag} from "ga-gtag";
import {getItemFromLocalStorage, setItemInLocalStorage} from "../../../helpers/localstorageHelper";
import {getTariffPrice} from "../../../helpers/getTarrifPrice";

const Step7 = () => {
    const getPrice = async (tariff) => {
        return await getTariffPrice(tariff, true)
    }

    const handleFinishForm = (form, price) => {
        gtag("event", "purchase", {
            transaction_id: form.contractId,
            value: price,
            currency: "EUR",
        })
        setItemInLocalStorage("currentStep", 0);
        setItemInLocalStorage("form", {});
    }

    useEffect(() => {
        const form = getItemFromLocalStorage("form")
        const paymentInterval = form.productIdActive.paymentIntervalMonths === 1
        if(paymentInterval){
            const activePlan = form.productIdActive
            const tariff = activePlan.selectedProductId === 1 ? "aktiv" : activePlan.selectedProductId === 2 ? "komfort" : "premium"
            getPrice(tariff).then((price) => {
                handleFinishForm(form, price.price)
            })
        } else {
            const price = form.productIdActive.price
            handleFinishForm(form, price)
        }
    }, [])

    return (
        <div className="thanks max-w-7xl flex flex-col items-center text-center">
            <img src={successForm} alt="success" />
            <h1>Vielen Dank!</h1>
            <p className="!mt-2">Schön, dass du bei uns bist.</p>
            <p className="mt-6">Wenn du uns auch so gut findest, wie wir dich, freuen wir uns über deine Bewertung.<br></br>
                Geht schnell und tut auch nicht weh - versprochen!</p>
            <a className="btn btn-secondary max-w-[320px] mt-6" href='https://de.trustpilot.com/evaluate/freeyou.ag' rel="noreferrer" target="_blank">Bewerte uns auf Trustpilot</a>
        </div>
    );
};

export default Step7;
