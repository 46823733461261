import React from "react";
import logo from "../assets/img/logo/logo.svg";

const Logo = ({ className }) => {
    return (
        <picture className={className}>
            <source srcSet={logo} media="(min-width: 1px)" />
            <img src={logo} alt="Free You logo" />
        </picture>
    );
};

export default Logo;
