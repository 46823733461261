export const getItemFromLocalStorage = (key) => {
    try {
        const serializedData = localStorage.getItem(key);
        if (serializedData === null) {
            return undefined;
        }
        return JSON.parse(serializedData);
    } catch (error) {
        console.error("Error getting data from localStorage:", error);
        return undefined;
    }
};

export const setItemInLocalStorage = (key, value) => {
    try {
        const serializedData = JSON.stringify(value);
        localStorage.setItem(key, serializedData);
    } catch (error) {
        console.error("Error setting data in localStorage:", error);
    }
};
