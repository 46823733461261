import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { formReducer } from "./formReducer";
import { stepReducer } from "./stepReducer";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
    form: formReducer,
    step: stepReducer,
});

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);
