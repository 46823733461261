import React, { useState } from "react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

const Accordion = ({ children, title }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion">
            <div className="accordion__title" onClick={toggleAccordion}>
                <h2>
                    {title}
                    <span>
                        {isOpen ? (
                            <SlArrowDown className="w-[24px] h-[24px] inline ml-[16px]" />
                        ) : (
                            <SlArrowUp className="w-[24px] h-[24px] inline ml-[16px]" />
                        )}
                    </span>
                </h2>
            </div>
            {isOpen && children}
        </div>
    );
};

export default Accordion;
