import React from "react";
import bgRight from '../../assets/img/bg-right.svg'
import bgLeft from '../../assets/img/bg-left.svg'
import bgLeft3 from '../../assets/img/bg-left3.svg'
import { useSelector } from "react-redux";
const Form = ({ className, children }) => {
    const steps = useSelector((state) => state.step);
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }
    return (
        <div className={`form-section ${steps[3].status === "current"? 'hide-scroll' : ''}`}>
            <div className={steps[3].status === "current" ? "form-section__bg form-section__bg--step3" : " form-section__bg"}>
                {steps[3].status !== "current" ?
                <>
                <img src={bgLeft} alt="section background"/>
                <img src={bgRight} alt="section background"/>
                </> :
                <>
                <img src={bgLeft3} alt="section background"/>
                </>
                }
            </div>
            <form onKeyPress={(e) => {e.key === 'Enter' && e.preventDefault()}}  className={classNames(
                    steps[6].status === "current" ? 'flex-large-width' : '',
                    steps[6].status === "complete" ? 'large-width' : '',
                    steps[3].status === "current" ? 'large-width' : ''
                )} onSubmit={(e) => e.preventDefault()}>
                {children}
            </form>
        </div>
    );
};

export default Form;
