import {getItemFromLocalStorage} from "./localstorageHelper";
import axios from "axios";
import {apiRoutes} from "../utils/apiRoutes";

export async function getTariffPrice(tariff, yearly){
    const store = getItemFromLocalStorage("form")
    const obj = {
        "version": "v1",
        "key": "repko",
        "payload": {
            "tariff": tariff,
            "identification": {
                "hsn": store.selectedCar.hsn,
                "tsn": store.selectedCar.tsn,
                "manufacturer": store.selectedCar.manufacturer,
                "category": store.selectedCar.category,
                "modelGroup": store.selectedCar.modelGroup,
                "fuelType": store.selectedCar.fuelType,
                "ps": store.selectedCar.ps,
                "model": store.selectedCar.model,
                "cylinder": store.selectedCar.cylinder,
                "doors": parseInt(store.selectedCar.doors),
                "kw": store.selectedCar.kw
            },
            "contract": {
                "paymentFrequency": yearly? "12m" : "1m"
            }
        }
    }
    const res = await axios.post(apiRoutes.quote, obj)
    return res.data
}

export const fractionalPart = (num) => {
    return (num % 1).toFixed(2).split(".")[1];
};