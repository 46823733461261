import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export const Loading = ({ isLoading, className }) => {
    return (
        <div className={`${isLoading ? 'flex' : 'hidden'} ${className} gap-2 items-center`}>
            <AiOutlineLoading3Quarters className="animate-spin" /> Laden...
        </div>
    )
}
