import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getItemFromLocalStorage,
    setItemInLocalStorage,
} from "../../../helpers/localstorageHelper";
import Button from "../../ui/Button";

const Step0 = (props) => {
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState(
        useSelector((state) => state.form.firstName)
    );

    const [lastName, setLastName] = useState(
        useSelector((state) => state.form.lastName)
    );

    const [bntDisabled, setBtnDisabled] = useState(true);

    const updateStep = (step) => {
        const currentFormData = getItemFromLocalStorage("form") || {};
        const updatedFormData = {
            ...currentFormData,
            firstName,
            lastName,
        };
        setItemInLocalStorage("form", updatedFormData);
        setItemInLocalStorage("currentStep", +step);

        dispatch({
            type: "FORM_UPDATE",
            payload: {
                firstName: firstName,
                lastName: lastName,
            },
        });

        dispatch({
            type: "STEP_CURRENT",
            payload: 1,
        });
    };

    useEffect(() => {
        setBtnDisabled(firstName.length < 1 || lastName.length < 1 || !/^([^0-9]*)$/.test(firstName) || !/^([^0-9]*)$/.test(lastName))
    }, [firstName, lastName]);

    return (
        <div className="">
            <div className="text-center">
                <h1 className="">Willkommen bei Freeyou!</h1>
                <p className="">
                    Und auf geht`s - berechne deine Reparaturkostenversicherung* in nur zwei Minuten zu deinem optimalen Preis.
                </p>
                <p className="p_b1-bold">Wie dürfen wir dich nennen?</p>
            </div>
            <div className="field">
                <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                >
                    Vorname
                </label>
                <div>
                    <input
                        id="first-name"
                        name="name"
                        type="text"
                        pattern="[^0-9]*"
                        required ={true}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="border-grey-800 text-black border-1 py-3 focus:text-black focus:outline-primary hover:border-black focus:outline-offset-0 valid:focus:outline-primary invalid:focus:outline-red-500 valid:focus:border-grey-800 invalid:focus:border-grey-800 valid:bg-white invalid:bg-white valid:focus:bg-green-50 valid:focus-within:bg-green-50 valid:focus-visible:bg-green-50 valid:visited:bg-green-50 valid:border-grey-800 invalid:border-grey-800 focus:border-primary focus:shadow-none focus:outline-none focus:ring-0 disabled:bg-grey-700 disabled:cursor-not-allowed disabled:hover:border-grey-800 rounded-md w-full "
                        placeholder="Vorname"
                    />
                </div>
            </div>
            <div className="field">
                <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                >
                    Nachname
                </label>
                <div>
                    <input
                        id="last-name"
                        name="last-name"
                        pattern="[^0-9]*"
                        type="text"
                        value={lastName}
                        required ={true}
                        onChange={(e) => setLastName(e.target.value)}
                        className="border-grey-800 text-black border-1 py-3 focus:text-black focus:outline-primary hover:border-black focus:outline-offset-0 valid:focus:outline-primary invalid:focus:outline-red-500 valid:focus:border-grey-800 invalid:focus:border-grey-800 valid:bg-white invalid:bg-white valid:focus:bg-green-50 valid:focus-within:bg-green-50 valid:focus-visible:bg-green-50 valid:visited:bg-green-50 valid:border-grey-800 invalid:border-grey-800 focus:border-primary focus:shadow-none focus:outline-none focus:ring-0 disabled:bg-grey-700 disabled:cursor-not-allowed disabled:hover:border-grey-800 rounded-md w-full "
                        placeholder="Nachname"
                    />
                </div>
            </div>
            <div className="flex form-section__buttons">
                <Button
                    onClick={() =>{updateStep(+props.step + 1)}}
                    className={"btn btn-primary"}
                    disabled={bntDisabled}
                >
                    Weiter
                </Button>
            </div>
            <p className="field notification__text p_b2 text-center md:text-left">*Die Reparaturkostenversicherung ist ein reines Zusatzprodukt - diese ersetzt nicht deine KFZ-Versicherung.</p>
        </div>
    );
};

export default Step0;
