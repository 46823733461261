import React from "react";
import {Loading} from "./Loading";

const Button = (props) => {
    const { children, isLoading } = props;
    return <button {...props}>
        {isLoading? <Loading isLoading={isLoading} className="justify-center"/> : children}
    </button>;
};

export default Button;
