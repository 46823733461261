import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import Form from "./Form/Form";
import Step0 from "./Form/Steps/Step0";
import Step1 from "./Form/Steps/Step1";
import Step2 from "./Form/Steps/Step2";
import Step3 from "./Form/Steps/Step3";
import Step4 from "./Form/Steps/Step4";
import Step5 from "./Form/Steps/Step5";
import Step6 from "./Form/Steps/Step6";
import Header from "./Header/Header";
import Step7 from "./Form/Steps/Step7";
import {gtag} from "ga-gtag";
import {gtagSteps} from "../store/stepReducer";

const Main = () => {
    const steps = useSelector((state) => state.step);
    useEffect(() => {
        const currentStep = steps.findIndex(step => step.status === "current")
        gtag('event', 'screen_view', {
            'app_name': 'RepkoCar',
            'screen_name': gtagSteps[currentStep]
        })
    }, [steps])

    return (
        <div className="">
            <Header className="mx-auto sm:px-6 lg:px-8" />
            <Form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                {steps[0].status === "current" && <Step0 step={0}/>}
                {steps[1].status === "current" && <Step1 step={1}/>}
                {steps[2].status === "current" && <Step2 step={2}/>}
                {steps[3].status === "current" && <Step3 step={3}/>}
                {steps[4].status === "current" && <Step4 step={4}/>}
                {steps[5].status === "current" && <Step5 step={5}/>}
                {steps[6].status === "current" && <Step6 step={6}/>}
                {steps[6].status === "complete" && <Step7 />}
                {/*{steps[6].status === "complete" && <SwiperCard />}*/}
            </Form>
        </div>
    );
};

export default Main;
