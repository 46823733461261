import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../../utils/routes.js";
import Main from "../Main";

const Routing = () => {
    return (
        <Routes>
            <Route path={routes.product_finder} element={<Main />} />
            <Route path="*" element={<Navigate to={routes.product_finder} />} />
        </Routes>
    );
};

export default Routing;
