import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./components/Routing/Routing";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW
}
TagManager.initialize(tagManagerArgs)

const App = () => {
    return (
        <BrowserRouter>
            <Routing />
        </BrowserRouter>
    );
};

export default App;
