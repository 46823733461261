import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getItemFromLocalStorage,
    setItemInLocalStorage,
} from "../../../helpers/localstorageHelper";
import Button from "../../ui/Button";
import ActivePlan from "../../ActivePlan";
import { IoRadioButtonOffSharp, IoRadioButtonOn } from "react-icons/io5";
import {fractionalPart, getTariffPrice} from "../../../helpers/getTarrifPrice";
import {LiaInfoCircleSolid} from "react-icons/lia";
import Notification from "../../Notification";
import { isValidIBAN } from "ibantools"

const Step5 = (props) => {
    const dispatch = useDispatch();

    const [activePlan, setActivePlan] = useState(
        useSelector((state) => state.form.productIdActive)
    );
    const [alternativeActivePlan, setAlternativeActivePlan] = useState({});
    const [checkedPayInterval, setCheckedPayInterval] = useState(
        useSelector(
            (state) => state.form.productIdActive.paymentIntervalMonths
        ) === 12
    );
    const [showInvoiceInstruction, setShowInvoiceInstruction] = useState(false);
    const [checkedPayMethod, setCheckedPayMethod] = useState(
        useSelector((state) => state.form.checkedPayMethod)
    );
    const [iban, setIban] = useState(useSelector((state) => state.form.iban));
    const [sepaAccepted, setSepaAccepted] = useState(
        useSelector((state) => state.form.sepaAccepted)
    );
    const [paymentMethod, setPaymentMethod] = useState(
        useSelector((state) => state.form.paymentMethod)
    );
    const [isInvoiceRecipient, setIsInvoiceRecipient] = useState(
        useSelector((state) => state.form.isInvoiceRecipient)
    );

    useEffect(() => {
        setIsInvoiceRecipient(!checkedPayMethod);
    }, [checkedPayMethod, isInvoiceRecipient])

    const handleRightClick = () => {
        changeInterval(activePlan, false).then((data) => {
            setAlternativeActivePlan(data)
            setCheckedPayInterval(true)
        })
    };

    const handleSwitchToggle = () => {
        changeInterval(activePlan, checkedPayInterval).then((data) => {
            setAlternativeActivePlan(data)
            setCheckedPayInterval((prevChecked) => !prevChecked);
            if (checkedPayInterval && !checkedPayMethod) {
                setCheckedPayMethod(true)
            }
        })
    };

    const handleLeftClick = () => {
        changeInterval(activePlan, true).then((data) => {
            setAlternativeActivePlan(data)
            setCheckedPayInterval(false);
            setCheckedPayMethod(true);
        })
    };

    const changeInterval = async (activePlan, yearly) => {
        if (!yearly) {
            return updatePrices(activePlan, true).then((data) => {
                return {
                    ...data,
                    paymentIntervalMonths: 12,
                }
            });
        } else {
            return updatePrices(activePlan, false).then((data) => {
                return {
                    ...data,
                    paymentIntervalMonths: 1,
                }
            });
        }
    }

    useEffect(() => {
        if(Object.keys(alternativeActivePlan).length){
            setActivePlan(alternativeActivePlan)
        }
    }, [alternativeActivePlan]);

    useEffect(() => {
        checkedPayMethod
            ? setPaymentMethod("sepa")
            : setPaymentMethod("invoice");
    }, [checkedPayMethod]);

    const isStep1Valid = useMemo(
        () => checkedPayMethod && isValidIBAN(iban) && sepaAccepted,
        [checkedPayMethod, iban, sepaAccepted]
    );

    const isStep3Valid = useMemo(
        () => !checkedPayMethod && isInvoiceRecipient,
        [checkedPayMethod, isInvoiceRecipient]
    );

    const isStep4Valid = useMemo(
        () =>
            !checkedPayMethod && !isInvoiceRecipient,
        [checkedPayMethod, isInvoiceRecipient]
    );

    const isButtonDisabled = !(
        isStep1Valid ||
        isStep3Valid ||
        isStep4Valid
    );

    const [btnDisabled, setBtnDisabled] = useState(isButtonDisabled);

    useEffect(() => {
        setBtnDisabled(isButtonDisabled);
    }, [isButtonDisabled]);

    async function updatePrices(obj, yearly) {
        const updatedObj = { ...obj };
        let tariffName = ''
        switch (obj.selectedProductId ){
            case 1:
                tariffName = 'aktiv'
                break;
            case 2:
                tariffName = 'komfort'
                break;
            default:
                tariffName = 'premium'
                break;
        }
        const prices = await getTariffPrice(tariffName, yearly)
        const wholePart = Math.trunc(prices.price)
        const fractional = fractionalPart(prices.price)
        const newFractional = fractional && fractional === "00" ? "00" : fractional.toString();

        updatedObj.cents = newFractional.toString();
        updatedObj.euros = wholePart.toString()

        const totalPrice = (
            parseFloat(updatedObj.euros) +
            parseFloat(`0.${updatedObj.cents}`)
        ).toFixed(2);
        updatedObj.price = totalPrice.toString();

        return updatedObj;
    }

    const updateStep = (step) => {
        const currentFormData = getItemFromLocalStorage("form") || {};
        const updatedFormData = {
            ...currentFormData,
            checkedPayMethod,
            iban,
            isInvoiceRecipient,
            paymentMethod,
            sepaAccepted,
            productIdActive: activePlan,
        };
        setItemInLocalStorage("form", updatedFormData);
        setItemInLocalStorage("currentStep", +step);
        dispatch({
            type: "STEP_CURRENT",
            payload: +step,
        });
        dispatch({
            type: "FORM_UPDATE",
            payload: {
                checkedPayMethod,
                iban,
                isInvoiceRecipient,
                paymentMethod,
                sepaAccepted,
                productIdActive: activePlan,
            },
        });
    };

    return (
        <div className="">
            <div className="text-center">
            <h1 className="">Wie möchtest du zahlen?</h1>
            <p className="">Wie möchtest du zahlen?</p>
            </div>
            <div className="flex items-center justify-center form-section__category">
                <button
                    className="text-blue-500 mr-2"
                    onClick={handleLeftClick}
                    style={{
                        color: !checkedPayInterval ? "#293B48" : "#777E90",
                    }}
                >
                    Monatlich
                </button>
                <label className="flex items-center cursor-pointer relative">
                    <input
                        type="checkbox"
                        id="toggle"
                        className="sr-only"
                        checked={checkedPayInterval}
                        onChange={handleSwitchToggle}
                    />
                    <div className="block bg-green-500 w-14 h-8 rounded-full"></div>
                    <div
                        className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform ${
                            checkedPayInterval
                                ? "transform translate-x-full"
                                : ""
                        }`}
                    ></div>
                    <div
                        className={`absolute left-0 top-0  bg-white rounded-full shadow-md transform transition-transform ${
                            checkedPayInterval
                                ? "translate-x-full bg-customToggle-dark"
                                : "bg-customToggle"
                        }`}
                    ></div>
                </label>
                <button
                    className="text-gray-500 ml-2"
                    onClick={handleRightClick}
                    style={{
                        color: checkedPayInterval ? "#293B48" : "#777E90",
                    }}
                >
                    Jährlich
                </button>
            </div>
            <div className="grid grid-cols-2 form-section__tabs">
                <button
                    className={`btn ${checkedPayMethod ? "active" : ""}`}
                    onClick={() => setCheckedPayMethod(true)}
                >
                    <span>
                        {checkedPayMethod ? (
                            <IoRadioButtonOn className="w-[24px] h-[24px] inline mr-[8px]" />
                        ) : (
                            <IoRadioButtonOffSharp className="w-[24px] h-[24px] inline mr-[8px]" />
                        )}
                        IBAN
                    </span>
                </button>
                <button
                    className={`btn items-center relative justify-center flex ${checkedPayMethod ? "disabled" : "active"}`}
                    onClick={() => checkedPayInterval? setCheckedPayMethod(false) : setShowInvoiceInstruction((prev) => !prev)}
                >
                    <span>
                        {!checkedPayMethod ? (
                            <IoRadioButtonOn className="w-[24px] h-[24px] inline mr-[8px]" />
                        ) : (
                            <IoRadioButtonOffSharp className="w-[24px] h-[24px] inline mr-[8px]" />
                        )}
                        Rechnung
                    </span>
                    <span className={`popover ${checkedPayInterval ? '!hidden' : ''}`}>
                        <LiaInfoCircleSolid className={`w-[24px] h-[24px] inline ml-[8px]`} />
                    </span>
                </button>
            </div>
            {showInvoiceInstruction && !checkedPayInterval && (
                <Notification handlerClose={() => {setShowInvoiceInstruction(false)}}>
                    Zahlung auf Rechnung ist ausschließlich bei jährlicher Zahlweise möglich
                </Notification>
            )}
            {checkedPayMethod && (
                <div className="form-section__wrapper">
                    <div className="form-section__wrapper">
                        <div className="field">
                            <h3>Wie lautet deine IBAN?</h3>
                            <div className="mt-1">
                                <input
                                    id="iban"
                                    name="iban"
                                    required = {true}
                                    type="text"
                                    value={iban}
                                    onChange={(e) => setIban(e.target.value)}
                                    className={`${!isValidIBAN(iban) ? 'invalid' : 'valid'} iban-field border-grey-800 uppercase text-black border-1 py-3 focus:text-black focus:outline-primary hover:border-black focus:outline-offset-0 valid:focus:outline-primary invalid:focus:outline-red-500 valid:focus:border-grey-800 invalid:focus:border-grey-800 valid:bg-white invalid:bg-white valid:focus:bg-green-50 valid:focus-within:bg-green-50 valid:focus-visible:bg-green-50 valid:visited:bg-green-50 valid:border-grey-800 invalid:border-grey-800 focus:border-primary focus:shadow-none focus:outline-none focus:ring-0 disabled:bg-grey-700 disabled:cursor-not-allowed disabled:hover:border-grey-800 rounded-md block w-full font-bold`}
                                    placeholder="IBAN"
                                />
                            </div>
                        </div>
                        <div className="field field--checkbox">
                                <input
                                    id="sepaAccepted"
                                    name="sepaAccepted"
                                    type="checkbox"
                                    onChange={(e) =>
                                        setSepaAccepted(e.target.checked)
                                    }
                                    checked={sepaAccepted}
                                    className="sr-only"
                                />
                            <label
                                htmlFor="sepaAccepted"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                <p>
                                    {sepaAccepted ? (
                                    <svg width="24" height="24" className="w-[24px] h-[24px] inline mr-[16px] active"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1.5" y="1.5" width="21" height="21" rx="5.5" stroke="#D900B5"/>
                                    <path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="#D900B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    ) : (
                                        <svg width="24" height="24" className="w-[24px] h-[24px] inline mr-[16px]"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.5" y="1.5" width="21" height="21" rx="5.5" stroke="#D900B5"/>
                                        <path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="#D900B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    )}
                                    Hiermit erteile ich das SEPA
                                    Lastschriftmandat zum Einzug der fälligen
                                    Beiträge.
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
            )}
            <ActivePlan activePlan={activePlan} />
            <div className={`flex gap-[20px] form-section__buttons ${!checkedPayMethod? 'large-gap' : ''}`}>
                <Button onClick={() => {
                    updateStep(props.step - 1)
                }} className={"btn btn-secondary"}>
                    Zurück
                </Button>
                <Button
                    onClick={() => {
                        updateStep(props.step + 1)
                    }}
                    className={"btn btn-primary"}
                    disabled={btnDisabled}
                >
                    Weiter
                </Button>
            </div>
        </div>
    );
};

export default Step5;
