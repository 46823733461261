import { getItemFromLocalStorage } from "../helpers/localstorageHelper";

/* const stepsStatus = ["complete", "current", "upcoming"]; */
const currentStep = getItemFromLocalStorage("currentStep") || 0;
const steps = [
    { id: 0, name: "Dein Name", status: "current" },
    { id: 1, name: "Auto (1)", status: "upcoming" },
    { id: 2, name: "Auto (2)", status: "upcoming" },
    { id: 3, name: "Angebot", status: "upcoming" },
    { id: 4, name: "Persönliches", status: "upcoming" },
    { id: 5, name: "Zahlung", status: "upcoming" },
    { id: 6, name: "Übersicht", status: "upcoming" },
];
export const gtagSteps = [
    'Start',
    'Erstzulassung',
    'Fahrzeug',
    'Tarif',
    'Kundendaten',
    'Zahlung',
    'Uebersicht'
]

const defaulState = stepCurrent(steps, currentStep);

function stepCurrent(arr, step) {
    return arr.map((elem) => {
        if (elem.id === step) {
            return { ...elem, status: "current" };
        } else if (elem.id < step) {
            return { ...elem, status: "complete" };
        } else {
            return { ...elem, status: "upcoming" };
        }
    });
}

export const stepReducer = (state = defaulState, action) => {
    switch (action.type) {
        case "STEP_UPDATE":
            const newState = [...state];
            action.payload.forEach((update) => {
                const { step, status } = update;
                if (newState[step]) {
                    newState[step].status = status;
                }
            });
            return newState;
        case "STEP_CURRENT":
            const updatedSteps = stepCurrent(state, action.payload);
            return updatedSteps;
        default:
            return state;
    }
};
