import React from "react";

const ActivePlanTotal = ({ activePlan }) => {
    return (
        <div className="flex items-center justify-between active-plan-total">
            <div>
                <h2>
                    {activePlan.selectedProductId === 1
                        ? "Aktiv"
                        : activePlan.selectedProductId === 2
                            ? "Komfort"
                            : "Premium"}
                </h2>
                <div>
                    <span>
                        Du zahlst
                        <span>
                            {activePlan.paymentIntervalMonths === 1
                                ? " Monatlich"
                                : " Jährlich"}
                        </span>
                    </span>
                </div>

            </div>
            <div className="active-plan-total__price flex items-center">
                <p>{activePlan.euros}</p>
                <div>
                    <p>{activePlan.cents}</p>
                    <span>
                        €
                        {activePlan.paymentIntervalMonths === 1
                            ? " monatlich."
                            : " jährl."}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ActivePlanTotal;
