import { getItemFromLocalStorage } from "../helpers/localstorageHelper";

const storageForm = getItemFromLocalStorage("form") || {};
const defaulState = {
    firstName: storageForm.firstName || "",
    lastName: storageForm.lastName || "",
    registrationDate: storageForm.registrationDate || "",
    kilometresDriven: storageForm.kilometresDriven || "",
    yearlyDistance: storageForm.yearlyDistance || "",
    productIdActive: storageForm.productIdActive || {
        selectedProductId: "",
        paymentIntervalMonths: "",
        price: "",
        euros: "",
        cents: "",
        productTariffeID: 1,
    },
    HSN: storageForm.HSN || "",
    TSN: storageForm.TSN || "",
    selectedCarId: storageForm.selectedCarId || "",
    selectedCar: storageForm.selectedCar || "",
    activeFormSelectedCar: storageForm.activeFormSelectedCar || 0,
    checkedPayMethod:
        storageForm.checkedPayMethod !== undefined
            ? storageForm.checkedPayMethod
            : true,
    accountOwnerCity: storageForm.accountOwnerCity || "",
    accountOwnerFirstName: storageForm.accountOwnerFirstName || "",
    accountOwnerLastName: storageForm.accountOwnerLastName || "",
    accountOwnerStreet: storageForm.accountOwnerStreet || "",
    accountOwnerStreetNumber: storageForm.accountOwnerStreetNumber || "",
    accountOwnerZipCode: storageForm.accountOwnerZipCode || "",
    iban: storageForm.iban || "",
    isAccountOwner:
        storageForm.isAccountOwner !== undefined
            ? storageForm.isAccountOwner
            : true,
    isInvoiceRecipient:
        storageForm.isInvoiceRecipient !== undefined
            ? storageForm.isInvoiceRecipient
            : false,
    paymentMethod: storageForm.paymentMethod || "",
    sepaAccepted:
        storageForm.sepaAccepted !== undefined
            ? storageForm.sepaAccepted
            : false,
    email: storageForm.email || "",
    licensePlatePartOne: storageForm.licensePlatePartOne || "",
    licensePlatePartThree: storageForm.licensePlatePartThree || "",
    licensePlatePartTwo: storageForm.licensePlatePartTwo || "",
    street: storageForm.street || "",
    streetNumber: storageForm.streetNumber || "",
    phone: storageForm.phone || "",
    vin: storageForm.vin || "",
    zipCode: storageForm.zipCode || "",
    birthday: storageForm.birthday || "",
    city: storageForm.city || "",
    countryCode: storageForm.countryCode || "+49",
    newsletter: storageForm.newsletter || false,
    step4Statuses: storageForm.step4Statuses || {
        dateRegistrationTest: false,
        inputLocationTest: false,
        inputEmailTest: false,
        inputMark1Test: false,
        inputMark2Test: false,
        inputMark3Test: false,
        inputPhoneTest: true,
        inputStreetTest: false,
        inputHouseNumberTest: false,
        inputFINTest: false,
        inputPostalCodeTest: false,
    },
};

export const formReducer = (state = defaulState, action) => {
    switch (action.type) {
        case "FORM_UPDATE":
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
