import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/Button";
import scribble from "../../../assets/img/scribble.png";
import { GiCheckMark } from "react-icons/gi";
import { CgClose } from "react-icons/cg";
import popular from '../../../assets/img/popular.svg'
import { IoRadioButtonOffSharp, IoRadioButtonOn } from "react-icons/io5";
import {
    getItemFromLocalStorage,
    setItemInLocalStorage,
} from "../../../helpers/localstorageHelper";
import {fractionalPart, getTariffPrice} from "../../../helpers/getTarrifPrice";

const Step3 = (props) => {
    const dispatch = useDispatch();

    const [tariffPrice, setTariffPrice] = useState([]);

    const [pay, setPay] = useState("");
    const [activeColumn, setActiveColumn] = useState(
        useSelector((state) => state.form.productIdActive.productTariffeID)
    );
    const [arr, setArr] = useState([]);
    const [tierDetails, setTierDetails] = useState([]);
    // coefficient API from api in step 2;
    const coefficient = 1;
    const [checked, setChecked] = useState(
        useSelector(
            (state) => state.form.productIdActive.paymentIntervalMonths
        ) === 12
    );

    const handleLeftClick = () => {
        fetchData(false).then(() => {
            setChecked(false)
        })
    };

    const handleRightClick = () => {
        fetchData(true).then(() => {
            setChecked(true)
        })
    };

    const handleSwitchToggle = () => {
        fetchData(!checked).then(() => {
            setChecked((prevChecked) => !prevChecked);
        })
    };

    const updateTierDetails = (updatedTiers) => {
        setTierDetails(updatedTiers);
    };

    const priceMounth = (tiers, coefficient, checked) => {
        const updatedTiers = tiers.map((tier) => {
            if ("priceCoef" in tier) {
                const multiplier = checked ? 12 : 1;
                const newEuros = tier.priceCoef * coefficient;
                const wholePart = Math.trunc(newEuros);
                const fractional = fractionalPart(newEuros);
                const newFractional =
                    fractional && fractional === "00" ? "00" : fractional.toString();
                let newPrice;
                if (typeof newEuros !== 'undefined') {
                    if (fractional && fractional === "00") {
                        newPrice = `${newEuros.toFixed(2)}`;
                    } else {
                        newPrice = Number(newEuros.toFixed(2)).toString();
                    }
                } else {
                    newPrice = '';
                }

                return {
                    ...tier,
                    paymentDetails: {
                        ...tier.paymentDetails,
                        paymentIntervalMonths: multiplier,
                        price: newPrice,
                        euros: wholePart.toString(),
                        cents: newFractional.toString(),
                    },
                };
            }

            return tier;
        });
        return updatedTiers;
    };

    const handleColumnClick = (index, arr) => {
        setActiveColumn(index);
    };

    const fetchData = async (yearly) => {
        try {
            const aktiv = await getTariffPrice('aktiv', yearly);
            const premium = await getTariffPrice('premium', yearly);
            const komfort = await getTariffPrice('komfort', yearly);
            setTariffPrice([aktiv, premium, komfort]);
        } catch (error) {
            console.error('Error fetching tariff prices:', error);
        }
    }

    useEffect(() => {
        fetchData(checked)
    }, [checked]);

    useEffect(() => {
        if (activeColumn !== undefined && arr.hasOwnProperty(activeColumn)) {
            const paymentDetails = arr[activeColumn].paymentDetails;
            setPay(paymentDetails);
        }
    }, [activeColumn, arr]);

    useEffect(() => {
        const tiers = [
            {
                name: "Aktiv",
                popular: false,
                selectedProductId: "1",
                priceCoef: tariffPrice.length > 0 ? tariffPrice[0].price : false,
                paymentDetails: {
                    productTariffeID: 0,
                    selectedProductId: 1,
                    paymentIntervalMonths: 1,
                },
                services: [
                    {
                        name: "Leistungen:",
                        values: [
                            {
                                include: true,
                                value: "Motor",
                            },
                            {
                                include: true,
                                value: "Schalt- und Automatikgetriebe",
                            },
                            {
                                include: true,
                                value: "Achsgetriebe",
                            },
                        ],
                    },
                    {
                        name: "Zusätzl. Leistungen Komfort:",
                        values: [
                            {
                                include: false,
                                value: "Abgasanlage",
                            },
                            {
                                include: false,
                                value: "Sicherheitssystem",
                            },
                            {
                                include: false,
                                value: "Kraftübertragung",
                            },
                            {
                                include: false,
                                value: "Lenkung",
                            },
                            {
                                include: false,
                                value: "Bremsen",
                            },
                            {
                                include: false,
                                value: "Kraftstoffanlage",
                            },
                            {
                                include: false,
                                value: "Fahrdynamiksysteme",
                            },
                            {
                                include: false,
                                value: "Elektrische Anlage",
                            },
                            {
                                include: false,
                                value: "Klimaanlage",
                            },
                            {
                                include: false,
                                value: "Kühlsystem",
                            },
                            {
                                include: false,
                                value: "Komfortelektronik",
                            },
                        ],
                    },
                    {
                        name: "Zusätzl. Leistungen Premium:",
                        values: [
                            {
                                include: false,
                                value: "Fahrassistenzsysteme",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Premium",
                popular: true,
                selectedProductId: "3",
                priceCoef: tariffPrice.length > 0? tariffPrice[1].price : false,
                description: 'Wir empfehlen dir eine Premium-Versicherung',
                paymentDetails: {
                    productTariffeID: 1,
                    selectedProductId: 3,
                    paymentIntervalMonths: 1,
                },
                services: [
                    {
                        name: "Leistungen:",
                        values: [
                            {
                                include: true,
                                value: "Motor",
                            },
                            {
                                include: true,
                                value: "Schalt- und Automatikgetriebe",
                            },
                            {
                                include: true,
                                value: "Achsgetriebe",
                            },
                        ],
                    },
                    {
                        name: "Zusätzl. Leistungen Komfort:",
                        values: [
                            {
                                include: true,
                                value: "Abgasanlage",
                            },
                            {
                                include: true,
                                value: "Sicherheitssystem",
                            },
                            {
                                include: true,
                                value: "Kraftübertragung",
                            },
                            {
                                include: true,
                                value: "Lenkung",
                            },
                            {
                                include: true,
                                value: "Bremsen",
                            },
                            {
                                include: true,
                                value: "Kraftstoffanlage",
                            },
                            {
                                include: true,
                                value: "Fahrdynamiksysteme",
                            },
                            {
                                include: true,
                                value: "Elektrische Anlage",
                            },
                            {
                                include: true,
                                value: "Klimaanlage",
                            },
                            {
                                include: true,
                                value: "Kühlsystem",
                            },
                            {
                                include: true,
                                value: "Komfortelektronik",
                            },
                        ],
                    },
                    {
                        name: "Zusätzl. Leistungen Premium:",
                        values: [
                            {
                                include: true,
                                value: "Fahrassistenzsysteme",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Komfort",
                popular: false,
                selectedProductId: "2",
                priceCoef: tariffPrice.length > 0? tariffPrice[2].price : false,
                paymentDetails: {
                    productTariffeID: 2,
                    selectedProductId: 2,
                    paymentIntervalMonths: 1,
                },
                services: [
                    {
                        name: "Leistungen:",
                        values: [
                            {
                                include: true,
                                value: "Motor",
                            },
                            {
                                include: true,
                                value: "Schalt- und Automatikgetriebe",
                            },
                            {
                                include: true,
                                value: "Achsgetriebe",
                            },
                        ],
                    },
                    {
                        name: "Zusätzl. Leistungen Komfort:",
                        values: [
                            {
                                include: true,
                                value: "Abgasanlage",
                            },
                            {
                                include: true,
                                value: "Sicherheitssystem",
                            },
                            {
                                include: true,
                                value: "Kraftübertragung",
                            },
                            {
                                include: true,
                                value: "Lenkung",
                            },
                            {
                                include: true,
                                value: "Bremsen",
                            },
                            {
                                include: true,
                                value: "Kraftstoffanlage",
                            },
                            {
                                include: true,
                                value: "Fahrdynamiksysteme",
                            },
                            {
                                include: true,
                                value: "Elektrische Anlage",
                            },
                            {
                                include: true,
                                value: "Klimaanlage",
                            },
                            {
                                include: true,
                                value: "Kühlsystem",
                            },
                            {
                                include: true,
                                value: "Komfortelektronik",
                            },
                        ],
                    },
                    {
                        name: "Zusätzl. Leistungen Premium:",
                        values: [
                            {
                                include: false,
                                value: "Fahrassistenzsysteme",
                            },
                        ],
                    },
                ],
            },
        ];
        const updatedTiers = priceMounth(tiers, coefficient, checked);
        updateTierDetails(updatedTiers);
        setArr(updatedTiers);
    }, [checked, tariffPrice]);

    const updateStep = (step) => {
        const productTariffeID = pay.productTariffeID;
        const selectedProductId = pay.selectedProductId;
        const paymentIntervalMonths = pay.paymentIntervalMonths;
        const price = pay.price;
        const euros = pay.euros;
        const cents = pay.cents;
        const currentFormData = getItemFromLocalStorage("form") || {};

        const updatedFormData = {
            ...currentFormData,
            productIdActive: {
                productTariffeID,
                selectedProductId,
                paymentIntervalMonths,
                price,
                euros,
                cents,
            },
        };
        setItemInLocalStorage("form", updatedFormData);
        setItemInLocalStorage("currentStep", +step);

        dispatch({
            type: "FORM_UPDATE",
            payload: {
                productIdActive: {
                    productTariffeID: productTariffeID,
                    selectedProductId: selectedProductId,
                    paymentIntervalMonths: paymentIntervalMonths,
                    price: price,
                    euros: euros,
                    cents: cents,
                },
            },
        });

        dispatch({
            type: "STEP_CURRENT",
            payload: +step,
        });
    };

    return (
        <div>
            <div className="flex justify-center flex-col items-center">
                <div className="text-center">
                <h1>Welcher Schutz darf`s denn sein?</h1>
                <p>Wie möchtest du zahlen?</p>
                </div>
                <div className="form-section__switch-cards w-[100%]">
                    <div>
                        <div className="flex max-w-[300px] mx-auto items-center relative form-section__category justify-center">
                            <div className="form-section__arrow hidden lg:block">
                                <img src={scribble} alt="scribble" />
                            </div>
                            <button
                                className="text-blue-500 mr-2"
                                onClick={handleLeftClick}
                                style={{ color: !checked ? "#293B48" : "#777E90" }}
                            >
                                Monatlich
                            </button>
                            <label className="flex items-center cursor-pointer relative">
                                <input
                                    type="checkbox"
                                    id="toggle"
                                    className="sr-only"
                                    checked={checked}
                                    onChange={handleSwitchToggle}
                                />
                                <div className="block bg-green-500 w-14 h-8 rounded-full"></div>
                                <div
                                    className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform ${
                                        checked ? "transform translate-x-full" : ""
                                    }`}
                                ></div>
                                <div
                                    className={`absolute left-0 top-0  bg-white rounded-full shadow-md transform transition-transform ${
                                        checked
                                            ? "translate-x-full bg-customToggle-dark"
                                            : "bg-customToggle"
                                    }`}
                                ></div>
                            </label>
                            <button
                                className="text-gray-500 ml-2"
                                onClick={handleRightClick}
                                style={{ color: checked ? "#293B48" : "#777E90" }}
                            >
                                Jährlich
                            </button>
                        </div>
                    </div>
                    <div className="form-section__switch-buttons grid grid-cols-3 gap-4 form-section__tabs">
                        {tierDetails.map((tier, index) => (
                            <button
                                key={index}
                                className={`btn flex justify-center items-center  ${
                                    activeColumn === index ? "border-green-500 active" : ""
                                }`}
                                onClick={() => handleColumnClick(index)}
                            >
                            {tier.name}
                               
                                <span className="flex items-center justify-center">
                                {activeColumn === index ? (
                                    <IoRadioButtonOn className="w-[13px] h-[13px] sm:w-[24px] sm:h-[24px]  inline ml-[5px] sm:ml-[8px] stroke-white" />
                                ) : (
                                    <IoRadioButtonOffSharp className="w-[13px] h-[13px] sm:w-[24px] sm:h-[24px]  inline ml-[5px] sm:ml-[8px]" />
                                )}
                            </span>
                            </button>
                        ))}
                    </div>

                </div>
                <div className={`flex justify-center  card-wrapper active-${activeColumn}-card`}>
                    {tierDetails.map((tier, index) => (
                        <div
                            key={index}
                            className={`mb-4 flex flex-col border-2 p-4 rounded-md category-card ${
                                activeColumn === index ? "border-green-500 category-card--active" : ""
                            }`}
                            onClick={() => handleColumnClick(index)}
                        >
                            <div className="flex justify-between">
                                <div className="category-card__title w-full">
                                    <h2>{tier.name}</h2>
                                    {tier?.description && <p>{tier.description}</p>}
                                </div>
                                {tier.popular && (
                                  <span className="popular flex items-center justify-center">
                                        Beliebt
                                        <img src={popular}  alt='popular icon'/>
                                  </span>
                                )}
                            </div>
                            {checked ? (
                                <div className="flex items-center category-card__price">
                                    <div>
                                        <p className="text-4xl">
                                            {tier.paymentDetails.euros}
                                        </p>
                                    </div>
                                    <div className="flex flex-col category-card__cent  text-sm">
                                        <div>{tier.paymentDetails.cents}</div>
                                        <div>€ jährlich.</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex items-center category-card__price">
                                    <div>
                                        <p className="text-4xl">
                                            {tier.paymentDetails.euros}
                                        </p>
                                    </div>
                                    <div className="flex flex-col category-card__cent text-sm">
                                        <div>{tier.paymentDetails.cents}</div>
                                        <div>€ monatlich.</div>
                                    </div>
                                </div>
                            )}
                            {tier.services.map((service, sIndex) => (
                                <div key={sIndex}>
                                    <h3>{service.name}</h3>
                                    <ul>
                                        {service.values.map((value, vIndex) => (
                                            <li key={vIndex}>
                                                {value.include ? (
                                                    <>
                                                    <GiCheckMark className="text-green-500 inline" />
                                                    {value.value}
                                                    </>
                                                ) : (
                                                    <>
                                                    <CgClose className="text-red-500 inline" />
                                                    <s>{value.value}</s>
                                                    </>
                                                )}
                                                
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex gap-[20px] form-section__buttons">
                <Button onClick={() => {
                    updateStep(props.step - 1)
                }} className={"btn btn-secondary"}>
                    Zurück
                </Button>
                <Button onClick={() => {
                        updateStep(props.step + 1)
                    }} className={"btn btn-primary"}>
                    Weiter
                </Button>
            </div>
        </div>
    );
};

export default Step3;
