import {getMonth, getYear, setYear, setMonth} from "date-fns";
import React from "react";
import {range} from "../../helpers/range";

export const CustomHeaderDatePicker = ({
                                           min_date,
                                           max_date,
                                           onDateChange,
                                           date,
                                           changeYear,
                                           changeMonth,
                                           decreaseMonth,
                                           increaseMonth,
                                           prevMonthButtonDisabled,
                                           nextMonthButtonDisabled
                                       }) => {
    const years = max_date?
        range(min_date, max_date, 1):
        range(min_date, getYear(new Date()), 1);
    const months = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ];

    const handleYearChange = (value) => {
        const newDate = setYear(date, value)
        changeYear(value)
        changeMonth(getMonth(newDate))
        if (onDateChange) {
            onDateChange(newDate)
        }
    }

    const handleMonthChange = (value) => {
        const newDate = setMonth(date, value)
        changeMonth(value)
        if (onDateChange) {
            onDateChange(newDate)
        }
    }

    return (
        <div className="flex relative justify-center gap-[10px]">
            <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className="react-datepicker__navigation custom-button react-datepicker__navigation--previous"
                aria-label="Previous Month">
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"></span>
            </button>
            <select
                value={getYear(date)}
                className="datepicker-select"
                onChange={({ target: { value } }) => handleYearChange(parseInt(value, 10))}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <select
                value={months[getMonth(date)]}
                className="datepicker-select"
                onChange={({target: {value}}) => handleMonthChange(months.indexOf(value))}
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className="react-datepicker__navigation custom-button react-datepicker__navigation--next">
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
            </button>
        </div>
    );
};