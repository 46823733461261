import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/index";
import App from "./App";

import "./static/css/main.css";

const app = ReactDOMClient.createRoot(document.getElementById("app"));
app.render(
    <Provider store={store}>
        <App />
    </Provider>
);
