import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getItemFromLocalStorage,
    setItemInLocalStorage,
} from "../../../helpers/localstorageHelper";
import Button from "../../ui/Button";
import { LiaInfoCircleSolid } from "react-icons/lia";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoRadioButtonOffSharp, IoRadioButtonOn } from "react-icons/io5";
import Notification from "../../Notification";
import hsnTsnHelp from "../../../assets/img/hsn-tsn-help.png";
import { apiRoutes } from "../../../utils/apiRoutes";
import axios from "axios";
import {Loading} from "../../ui/Loading";
import {getCorrectModel} from "../../../helpers/getCorrectModel";

const Step2 = (props) => {
    const dispatch = useDispatch();

    const firstName = useSelector((state) => state.form.firstName);

    const [HSN, setHSN] = useState(useSelector((state) => state.form.HSN));
    const [TSN, setTSN] = useState(useSelector((state) => state.form.TSN));
    const [selectedCar, setSelectedCar] = useState(
        useSelector((state) => state.form.selectedCar)
    );
    const [selectedCarId, setSelectedCarId] = useState(
        useSelector((state) => state.form.selectedCarId)
    );
    const [activeFormSelectedCar, setActiveFormSelectedCar] = useState(
        useSelector((state) => state.form.activeFormSelectedCar)
    );
    const form = useSelector((state) => state.form);
    const [isSelectedCar, setIsSelectedCar] = useState(!!form.selectedCarId)
    const [manufacturerList, setManufacturerList] = useState([]);
    const [manufacturerSelected, setManufacturerSelected] =
        useState("unSelected");
    const [modelList, setModelList] = useState([]);
    const [modelSelected, setModelSelected] = useState("unSelected");
    const [fuelList, setFuelList] = useState([]);
    const [fuelSelected, setFuelSelected] = useState("unSelected");
    const [categoryList, setCategoryList] = useState([]);
    const [categorySelected, setCategorySelected] = useState("unSelected");
    const [powerList, setPowerList] = useState([]);
    const [powerSelected, setPowerSelected] = useState("unSelected");
    const [carMarkeModel, setCarMarkeModel] = useState([selectedCar]);

    const [HSNClueShow, setHSNClueShow] = useState(false);
    const [TSNClueShow, setTSNClueShow] = useState(false);
    const [carHsnTsn, setCarHsnTsn] = useState(null);

    async function getManufacturerList() {
        try {
            const response = await axios.get(apiRoutes.cars);

            return response.data;
        } catch (error) {
            throw new Error("Failed to retrieve data");
        }
    }

    async function postCarList(params) {
        try {
            const response = await axios.post(apiRoutes.cars, params);

            return response.data;
        } catch (error) {
            setIsSelectedCar(false)
            return false
        }
    }

    async function getCarHsnTsn(params) {
        try {
            const response = await axios.post(apiRoutes.hsntsn, params);
            return response.data
        } catch (error) {
            throw new Error("Failed to retrieve data");
        }
    }

    const handleCarChange = (e, car) => {
        setSelectedCarId(e.target.value);
        setSelectedCar(car);
    };

    const handleManufacturerList = (e, disappearCar = true) => {
        if(disappearCar){
            setSelectedCarId('')
        }
        const reqParams = { manufacturer: e };
        postCarList(reqParams).then((data) => {
            setManufacturerSelected(e)
            setModelList(data)
            setModelSelected("unSelected");
            setFuelList([]);
            setFuelSelected("unSelected");
            setCategoryList([]);
            setCategorySelected("unSelected");
            setPowerList([]);
            setPowerSelected("unSelected");
            setCarMarkeModel([])
        });
    };

    const handleModelList = (e, disappearCar = true) => {
        if(disappearCar){
            setSelectedCarId('')
        }
        const reqParams = {
            manufacturer: manufacturerSelected,
            model: e,
        };
        postCarList(reqParams).then((data) => {
            setModelSelected(e)
            setFuelList(data);
            setFuelSelected("unSelected");
            setCategoryList([]);
            setCategorySelected("unSelected");
            setPowerList([]);
            setPowerSelected("unSelected");
            setCarMarkeModel([])
        });
    };

    const handleFuelList = (e, disappearCar = true) => {
        if(disappearCar){
            setSelectedCarId('')
        }
        const reqParams = {
            manufacturer: manufacturerSelected,
            model: modelSelected,
            fuel: e,
        };
        postCarList(reqParams).then((data) => {
            setFuelSelected(e);
            setCategoryList(data);
            setCategorySelected("unSelected");
            setPowerList([]);
            setPowerSelected("unSelected");
            setCarMarkeModel([])
        });
    };

    const handleCategoryList = (e, disappearCar = true) => {
        if(disappearCar){
            setSelectedCarId('')
        }
        const reqParams = {
            manufacturer: manufacturerSelected,
            model: modelSelected,
            fuel: fuelSelected,
            category: e,
        };
        postCarList(reqParams).then((data) => {
            setCategorySelected(e);
            if(data.length){
                const sortedNumbersArray = data.map(Number).sort((a, b) => a - b);
                setPowerList(sortedNumbersArray);
                setPowerSelected("unSelected");
                setCarMarkeModel([])
            } else {
                setIsSelectedCar(false)
            }
        });
    };

    const handlePowerList = (e, disappearCar = true) => {
        if(disappearCar){
            setSelectedCarId('')
        }
        const reqParams = {
            manufacturer: manufacturerSelected,
            model: modelSelected,
            fuel: fuelSelected,
            category: categorySelected,
            power: e
        };

        postCarList(reqParams).then((data) => {
            setPowerSelected(e);
            setCarMarkeModel(data);
        });
    };

    useEffect(() => {
        getManufacturerList().then((data) => {
            setManufacturerList(data);
            if(form.selectedCarId){
                const value = data.filter((manufacturer) => manufacturer.toUpperCase() === form.selectedCar.manufacturer.toUpperCase())
                if(value.length){
                    handleManufacturerList(value[0], false)
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (HSN && TSN && HSN.length === 4 && /^[A-Za-z0-9]*$/.test(HSN) && TSN.length ===3 && /^[A-Za-z0-9]*$/.test(TSN)) {
                getCarHsnTsn({"hsn": HSN, "tsn": TSN})
                    .then((data) => {
                        setCarHsnTsn(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [HSN, TSN]);

    useEffect(() => {
        setTSN(selectedCar.tsn)
        setHSN(selectedCar.hsn)
    }, [selectedCar])

    useEffect(() => {
        if(isSelectedCar && manufacturerSelected !== 'unSelected'){
            const model = modelList.filter(el => el.includes(form.selectedCar.modelGroup))
            if(model.length === 1){
                handleModelList(model[0], false)
            } else {
                handleModelList(getCorrectModel(form.selectedCar.model), false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manufacturerSelected])

    useEffect(() => {
        if(isSelectedCar && modelSelected !== 'unSelected'){
            handleFuelList(form.selectedCar.fuelType, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelSelected])

    useEffect(() => {
        if(isSelectedCar && fuelSelected !== 'unSelected'){
            if(form.selectedCar.category === 'Hochraumkombi'){
                handleCategoryList('Kombi', false)
            } else {
                handleCategoryList(form.selectedCar.category, false)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fuelSelected])

    useEffect(() => {
        if(isSelectedCar && categorySelected !== 'unSelected'){
            handlePowerList(`${form.selectedCar.kw}`, false)
            setIsSelectedCar(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categorySelected])

    const updateStep = (step) => {
        const currentFormData = getItemFromLocalStorage("form") || {};
        const updatedFormData = {
            ...currentFormData,
            HSN,
            TSN,
            selectedCar,
            selectedCarId,
            activeFormSelectedCar,
        };
        setItemInLocalStorage("form", updatedFormData);
        setItemInLocalStorage("currentStep", +step);

        dispatch({
            type: "STEP_CURRENT",
            payload: +step,
        });
        dispatch({
            type: "FORM_UPDATE",
            payload: {
                HSN: HSN,
                TSN: TSN,
                selectedCar: selectedCar,
                selectedCarId: selectedCarId,
                activeFormSelectedCar: activeFormSelectedCar,
            },
        });
    };

    return (
        <div className="">
            <div className="text-center">
                <h1 className="">Stell uns dein Auto vor, {firstName}</h1>
            </div>
            <Notification>
                Mit der HSN/TSN aus deinem Fahrzeugschein findest du garantiert
                das richtige Auto.
            </Notification>
            <label>Suche dein Auto über</label>
            <div className="grid grid-cols-2 form-section__tabs">
                <button
                    className={`btn ${activeFormSelectedCar ? "" : "active"}`}
                    onClick={() => setActiveFormSelectedCar(0)}
                >
                    <span className="flex items-center justify-center">
                        {!activeFormSelectedCar ? (
                            <IoRadioButtonOn className="w-[13px] h-[13px] sm:w-[24px] sm:h-[24px]  inline mr-[5px] sm:mr-[8px] stroke-white" />
                        ) : (
                            <IoRadioButtonOffSharp className="w-[13px] h-[13px] sm:w-[24px] sm:h-[24px]  inline mr-[5px] sm:mr-[8px]" />
                        )}
                        HSN/TNS
                    </span>
                </button>
                <button
                    className={`btn ${activeFormSelectedCar ? "active" : ""}`}
                    onClick={() => setActiveFormSelectedCar(1)}
                >
                    <span className="flex items-center justify-center">
                        {activeFormSelectedCar ? (
                            <IoRadioButtonOn className="w-[13px] h-[13px] sm:w-[24px] sm:h-[24px]  inline mr-[5px] sm:mr-[8px] stroke-white" />
                        ) : (
                            <IoRadioButtonOffSharp className="w-[13px] h-[13px] sm:w-[24px] sm:h-[24px]  inline mr-[5px] sm:mr-[8px]" />
                        )}
                        Marke & Modell
                    </span>
                </button>
            </div>
            {!activeFormSelectedCar ? (
                <div>
                    <div className="field">
                        <div className="flex items-center">
                            <label
                                htmlFor="hsn"
                                className="inline text-sm  leading-6 text-gray-900"
                            >
                                HSN
                            </label>
                            <span>
                                <LiaInfoCircleSolid
                                    className={`w-[24px] h-[24px] inline ml-[8px] 4px] mb-[4px] cursor-pointer ${
                                        HSNClueShow ? "hidden" : ""
                                    }`}
                                    onClick={() => setHSNClueShow(true)}
                                />
                                <IoIosCloseCircleOutline
                                    className={`w-[24px] h-[24px] inline ml-[8px] mb-[4px] cursor-pointer ${
                                        HSNClueShow ? "" : "hidden" 
                                    }`}
                                    onClick={() => setHSNClueShow(false)}
                                />
                            </span>
                        </div>
                        <p
                            className={`mt-0 mb-[10px] tools text-sm text-gray-500 ${
                                HSNClueShow ? "" : "hidden"
                            }`}
                            id="hsn-clue"
                        >
                            Die HSN ist die Herstellerschlüsselnummer deines
                            Autos. Du findest sie in deinem Fahrzeugschein unter
                            Punkt 2.1.
                        </p>
                        <div >
                            <input
                                type="text"
                                name="hsn"
                                id="hsn"
                                pattern="[A-Za-z0-9]+"
                                required ={true}
                                minLength={4}
                                maxLength={4}
                                className="border-grey-800 text-black border-1 py-3 focus:text-black focus:outline-primary hover:border-black focus:outline-offset-0 valid:focus:outline-primary invalid:focus:outline-red-500 valid:focus:border-grey-800 invalid:focus:border-grey-800 valid:bg-white invalid:bg-white valid:focus:bg-green-50 valid:focus-within:bg-green-50 valid:focus-visible:bg-green-50 valid:visited:bg-green-50 valid:border-grey-800 invalid:border-grey-800 focus:border-primary focus:shadow-none focus:outline-none focus:ring-0 disabled:bg-grey-700 disabled:cursor-not-allowed disabled:hover:border-grey-800 rounded-md w-full "
                                aria-describedby="hsn-clue"
                                value={HSN}
                                onChange={(e) => setHSN(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <div className="flex items-center">
                            <label
                                htmlFor="hsn"
                                className="inline text-sm font-medium leading-6 text-gray-900"
                            >
                               TSN
                            </label>
                            <span>
                                <LiaInfoCircleSolid
                                    className={`w-[24px] h-[24px] inline mb-[4px] ml-[8px] ${
                                        TSNClueShow ? "hidden" : ""
                                    }`}
                                    onClick={() => setTSNClueShow(true)}
                                />
                                <IoIosCloseCircleOutline
                                    className={`w-[24px] h-[24px] inline mb-[4px] ml-[8px] ${
                                        TSNClueShow ? "" : "hidden"
                                    }`}
                                    onClick={() => setTSNClueShow(false)}
                                />
                            </span>
                        </div>
                        <p
                            className={`mt-0 mb-[10px] tools text-sm text-gray-500 ${
                                TSNClueShow ? "" : "hidden"
                            }`}
                            id="tsn-clue"
                        >
                            Die TSN ist die Typschlüsselnummer deines Autos. Wir
                            benötigen hiervon die ersten drei Stellen. Du
                            findest sie in deinem Fahrzeugschein unter Punkt
                            2.2.
                        </p>
                        <div >
                            <input
                                type="text"
                                name="tsn"
                                id="tsn"
                                pattern="[A-Za-z0-9]+"
                                required ={true}
                                minLength={3}
                                maxLength={3}
                                className="border-grey-800 text-black border-1 py-3 focus:text-black focus:outline-primary hover:border-black focus:outline-offset-0 valid:focus:outline-primary invalid:focus:outline-red-500 valid:focus:border-grey-800 invalid:focus:border-grey-800 valid:bg-white invalid:bg-white valid:focus:bg-green-50 valid:focus-within:bg-green-50 valid:focus-visible:bg-green-50 valid:visited:bg-green-50 valid:border-grey-800 invalid:border-grey-800 focus:border-primary focus:shadow-none focus:outline-none focus:ring-0 disabled:bg-grey-700 disabled:cursor-not-allowed disabled:hover:border-grey-800 rounded-md w-full "
                                aria-describedby="tsn-clue"
                                value={TSN}
                                onChange={(e) => setTSN(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-section__image">
                        <p className="p_b2">
                            Hier findest du die HSN/TSN in deinem
                            Fahrzeugschein:
                        </p>
                        <img src={hsnTsnHelp} alt="hsn tsn help" />
                    </div>
                    {carHsnTsn && carHsnTsn.length ?(
                        carHsnTsn.map((car) => {
                            return (
                                <div
                                    key={car.id}
                                    className="car-card mt-[20px] mb-[20px]"
                                >
                                    <label
                                        htmlFor={car.id}
                                        className="flex"
                                    >
                                        <input
                                            type="radio"
                                            name="cars-list"
                                            id={car.id}
                                            className="sr-only"
                                            value={car.id}
                                            checked={
                                                +selectedCarId ===
                                                +car.id
                                            }
                                            onChange={(e) =>
                                                handleCarChange(e, car)
                                            }
                                        />
                                        <span>
                                            {+selectedCarId ===
                                            +car.id ? (
                                                <IoRadioButtonOn className="w-[24px] h-[24px] inline mr-[8px] active" />
                                            ) : (
                                                <IoRadioButtonOffSharp className="w-[24px] h-[24px] inline mr-[8px]" />
                                            )}
                                        </span>
                                        <div>
                                            <h3>{car.manufacturer} {car.model}</h3>
                                            <span>{car.ps}PS </span>
                                            <span>({car.kw}kW) </span>
                                            <span>{car.fuelType}, </span>
                                            <span>{car.category}, </span>
                                            <span>{car.transmission}, </span>
                                            <span>HSN:{car.hsn}, </span>
                                            <span>TSN:{car.tsn}</span>
                                        </div>
                                    </label>
                                </div>
                            );
                        })
                    ) : (
                        <p className="car-card text-center text-[#ef4444]">Kein Fahrzeug gefunden</p>
                    )}
                </div>
            ) : (
                <div>
                    <Loading className="justify-center mt-4" isLoading={isSelectedCar} />
                    <div className={ isSelectedCar? 'hidden' : '' }>
                        <div className="field">
                            <label
                                htmlFor="manufacturer"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Hersteller
                            </label>
                            <select
                                id="manufacturer"
                                name="manufacturer"
                                required ={true}
                                className=" border-1 rounded-md py-3 border-grey-800 my-auto focus:text-black focus:border-primary block w-full active:border-gray-800 text-base"
                                onChange={(e) => handleManufacturerList(e.target.value)}
                                value={manufacturerSelected}
                            >
                                <option
                                    disabled
                                    value="unSelected"
                                    key="unSelected"
                                ></option>
                                {manufacturerList.map((elem) => {
                                    return (
                                        <option value={elem} key={elem}>
                                            {elem}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {modelList.length > 0 && (
                            <div className="field">
                                <label
                                    htmlFor="model"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Model
                                </label>
                                <select
                                    id="model"
                                    name="model"
                                    className=" border-1 rounded-md py-3 border-grey-800 my-auto focus:text-black focus:border-primary block w-full active:border-gray-800 text-base"
                                    onChange={(e) => handleModelList(e.target.value)}
                                    value={modelSelected}
                                >
                                    <option
                                        disabled
                                        value="unSelected"
                                        key="unSelected"
                                    ></option>
                                    {modelList.map((elem) => {
                                        return (
                                            <option value={elem} key={elem}>
                                                {elem}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        {fuelList.length > 0 && (
                            <div className="field">
                                <label
                                    htmlFor="fuel"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Kraftstoff
                                </label>
                                <select
                                    id="fuel"
                                    name="fuel"
                                    className=" border-1 rounded-md py-3 border-grey-800 my-auto focus:text-black focus:border-primary block w-full active:border-gray-800 text-base"
                                    onChange={(e) => handleFuelList(e.target.value)}
                                    value={fuelSelected}
                                >
                                    <option
                                        disabled
                                        value="unSelected"
                                        key="unSelected"
                                    ></option>
                                    {fuelList.map((elem) => {
                                        return (
                                            <option value={elem} key={elem}>
                                                {elem}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        {categoryList.length > 0 && (
                            <div className="field">
                                <label
                                    htmlFor="category"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Kategorie
                                </label>
                                <select
                                    id="category"
                                    name="category"
                                    className=" border-1 rounded-md py-3 border-grey-800 my-auto focus:text-black focus:border-primary block w-full active:border-gray-800 text-base"
                                    onChange={(e) => handleCategoryList(e.target.value)}
                                    value={categorySelected}
                                >
                                    <option
                                        disabled
                                        value="unSelected"
                                        key="unSelected"
                                    ></option>
                                    {categoryList.map((elem) => {
                                        return (
                                            <option value={elem} key={elem}>
                                                {elem}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        {powerList.length > 0 && (
                            <div className="field">
                                <label
                                    htmlFor="power"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Leistung
                                </label>
                                <select
                                    id="power"
                                    name="power"
                                    className=" border-1 rounded-md py-3 border-grey-800 my-auto focus:text-black focus:border-primary block w-full active:border-gray-800 text-base"
                                    onChange={(e) => handlePowerList(e.target.value)}
                                    value={powerSelected}
                                >
                                    <option
                                        disabled
                                        value="unSelected"
                                        key="unSelected"
                                    ></option>
                                    {powerList.map((elem) => {
                                        return (
                                            <option value={elem} key={elem}>
                                                {elem} KW
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        <div>
                            {carMarkeModel.length > 0 && (
                                <p className="car-card">Bitte wähle das zutreffende Auto aus…</p>
                            )}

                            {carMarkeModel.length > 0 && carMarkeModel[0] !== "" &&
                                carMarkeModel?.map((car) => {
                                    return (
                                        <div
                                            key={car.id}
                                            className="car-card mt-[20px] mb-[20px]"
                                        >
                                            <label
                                                htmlFor={car.id}
                                                className="flex"
                                            >
                                                <input
                                                    type="radio"
                                                    name="cars-list"
                                                    id={car.id}
                                                    className="sr-only"
                                                    value={car.id}
                                                    checked={
                                                        +selectedCarId ===
                                                        +car.id
                                                    }
                                                    onChange={(e) =>
                                                        handleCarChange(e, car)
                                                    }
                                                />
                                                <span>
                                                {+selectedCarId ===
                                                +car.id ? (
                                                    <IoRadioButtonOn className="w-[24px] h-[24px] inline mr-[8px] active" />
                                                ) : (
                                                    <IoRadioButtonOffSharp className="w-[24px] h-[24px] inline mr-[8px] " />
                                                )}
                                            </span>
                                                <div>
                                                    <h3>{car.manufacturer} {car.model}</h3>
                                                    <span>{car.ps}PS </span>
                                                    <span>({car.kw}kW) </span>
                                                    <span>{car.fuelType}, </span>
                                                    <span>{car.category}, </span>
                                                    <span>{car.transmission}, </span>
                                                    <span>HSN:{car.hsn}, </span>
                                                    <span>TSN:{car.tsn}</span>
                                                </div>
                                            </label>
                                        </div>
                                    );
                                })}

                            {carMarkeModel.length <= 0 && powerSelected !== 'unSelected' && (
                                <p className="car-card text-center text-[#ef4444]">Kein Fahrzeug gefunden</p>
                            )}

                            {selectedCar && selectedCarId && carMarkeModel.length === 0 && (
                                <div
                                    key={selectedCar.id}
                                    className="car-card mt-[20px] mb-[20px]"
                                >
                                    <label
                                        htmlFor={selectedCar.id}
                                        className="flex"
                                    >
                                    <span>
                                        <IoRadioButtonOn className="w-[24px] h-[24px] inline mr-[8px] active" />
                                    </span>
                                        <div>
                                            <h3>{selectedCar.manufacturer} {selectedCar.model}</h3>
                                            <span>{selectedCar.ps}PS </span>
                                            <span>({selectedCar.kw}kW) </span>
                                            <span>{selectedCar.fuelType}, </span>
                                            <span>{selectedCar.category}, </span>
                                            <span>{selectedCar.transmission}, </span>
                                            <span>HSN:{selectedCar.hsn}, </span>
                                            <span>TSN:{selectedCar.tsn}</span>
                                        </div>
                                    </label>
                                </div>
                            )}

                            <Notification>
                                Dein Auto ist nicht dabei? Dann schau nochmal, ob deine Angaben stimmen oder suche dein Auto am besten direkt über die HSN/TSN aus dem Fahrzeugschein.
                            </Notification>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex gap-[20px] form-section__buttons">
                <Button onClick={() => {
                    updateStep(props.step - 1)
                }} className={"btn btn-secondary"}>
                    Zurück
                </Button>
                <Button
                    onClick={() => {
                        updateStep(props.step + 1)
                    }}
                    className={"btn btn-primary"}
                    disabled={!selectedCarId}
                >
                    Weiter
                </Button>
            </div>
        </div>
    );
};

export default Step2;
