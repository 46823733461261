import React, { useState } from "react";
import { LiaInfoCircleSolid } from "react-icons/lia";
import { IoMdClose } from "react-icons/io";

const Notification = ({ handlerClose, children }) => {
    const [isClosed, setIsClosed] = useState(false);

    const handleClose = () => {
        setIsClosed(true);
        if (handlerClose) {
            handlerClose()
        }
    }

    return (
        <div className={isClosed ? "hidden" : "notification flex gap-[10px]"}>
            <div className="flex">
                <LiaInfoCircleSolid className="w-[24px] h-[24px] inline notification__info-icon" />
            </div>
            <p className="notification__text p_b2">{children}</p>
            <button onClick={handleClose} className="flex">
                <IoMdClose className="w-[24px] h-[24px] inline notification__close-icon" />
            </button>
        </div>
    );
};

export default Notification;
