export const getCorrectModel = (model) => {
    switch (true) {
        case model.includes('500L'):
            return '500L'
        case model.includes('500X'):
            return '500X'
        case model.includes('500 '):
            return '500'
        case model.includes('Doblo Cargo'):
            return 'Doblo Cargo'
        case model.includes('Doblo'):
            return 'Doblo'
        case model.includes('Ducato-E'):
            return 'Ducato-E'
        case model.includes('Ducato'):
            return 'Ducato'
        case model.includes('Punto'):
            return 'Punto'
        case model.includes('Spider'):
            return '124 Spider'
        case model.includes('Q5') && model.includes('Sportback'):
            return 'Q5 Sportback'
        case model.includes('Q5'):
            return 'Q5'
        case model.includes('C3 Aircross'):
            return 'C3 Aircross'
        case model.includes('C3 Picasso'):
            return 'C3 Picasso'
        case model.includes('C3'):
            return 'C3'
        case model.includes('C4 Aircross'):
            return 'C4 Aircross'
        case model.includes('C4 Cactus'):
            return 'C4 Cactus'
        case model.includes('C4 Spacetourer'):
            return 'C4 Spacetourer'
        case model.includes('C4 Picasso'):
            return 'C4 Picasso'
        case model.includes('C4'):
            return 'C4'
        case model.includes('C5 X'):
            return 'C5 X'
        case model.includes('C5 Aircross'):
            return 'C5 Aircross'
        case model.includes('C5'):
            return 'C5'
        case model.includes('DS3 Crossback'):
            return 'DS3 Crossback'
        case model.includes('DS3'):
            return 'DS3'
        case model.includes('DS4 SUV'):
            return 'DS4 SUV'
        case model.includes('DS4'):
            return 'DS4'
        case model.includes('Jumpy'):
            return 'Jumpy'
        case model.includes('Xsara Picasso'):
            return 'Xsara Picasso'
        case model.includes('Xsara'):
            return 'Xsara'
        case model.includes('Gran Move'):
            return 'Gran Move'
        case model.includes('Move'):
            return 'Move'
        case model.includes('C-MAX'):
            return 'C-Max'
        case model.includes('Focus C-Max'):
            return 'Focus C-Max'
        case model.includes('Focus'):
            return 'Focus'
        case model.includes('Ka+'):
            return 'Ka+'
        case model.includes('Mustang'):
            return 'Mustang'
        case model.includes('Tourneo Connect'):
            return 'Tourneo Connect'
        case model.includes('Tourneo Courier'):
            return 'Tourneo Courier'
        case model.includes('Tourneo'):
            return 'Tourneo'
        case model.includes('Transit Connect'):
            return 'Transit Connect'
        case model.includes('Transit Custom'):
            return 'Transit Custom'
        case model.includes('Transit'):
            return 'Transit'
        case model.includes('Civic Hybrid'):
            return 'Civic Hybrid'
        case model.includes('Civic IMA'):
            return 'Civic IMA'
        case model.includes('Civic'):
            return 'Civic'
        case model.includes('Atos Prime'):
            return 'Atos Prime'
        case model.includes('Atos'):
            return 'Atos'
        case model.includes('H-100'):
            return 'H-100'
        case model.includes('H-1'):
            return 'H-1'
        case model.includes('IONIQ 5'):
            return 'IONIQ 5'
        case model.includes('IONIQ'):
            return 'IONIQ'
        case model.includes('Grand Cherokee'):
            return 'Grand Cherokee'
        case model.includes('Cherokee'):
            return 'Cherokee'
        case model.includes('Soul'):
            return 'Soul'
        case model.includes('XCeed'):
            return 'XCeed'
        case model.includes('Ceed'):
            return 'Ceed'
        case model.includes('Discovery Sport'):
            return 'Discovery Sport'
        case model.includes('Discovery'):
            return 'Discovery'
        case model.includes('Range Rover Velar'):
            return 'Range Rover Velar'
        case model.includes('Range Rover Evoque'):
            return 'Range Rover Evoque'
        case model.includes('Range Rover Sport'):
            return 'Range Rover Sport'
        case model.includes('323'):
            return '323'
        case model.includes('CX-3'):
            return 'CX-3'
        case model.includes('CX-30'):
            return 'CX-30'
        case model.includes('MX-3'):
            return 'MX-3'
        case model.includes('MX-30'):
            return 'MX-30'
        case model.includes('626'):
            return '626'
        case model.includes('CX-6'):
            return 'CX-6'
        case model.includes('Xedos 6'):
            return 'Xedos 6'
        case model.includes('Xedos 9'):
            return 'Xedos 9'
        case model.includes('CX-5'):
            return 'CX-5'
        case model.includes('MX-5'):
            return 'MX-5'
        case model.includes('Mini Cooper'):
            return 'Mini Cooper'
        case model.includes('Mini'):
            return 'Mini'
        case model.includes('Eclipse Cross Plug-In Hybrid'):
            return 'Eclipse Cross Plug-In Hybrid'
        case model.includes('Eclipse Cross'):
            return 'Eclipse Cross'
        case model.includes('Eclipse'):
            return 'Eclipse'
        case model.includes('Pajero Pinin'):
            return 'Pajero Pinin'
        case model.includes('Pajero'):
            return 'Pajero'
        case model.includes('Almera Tino'):
            return 'Almera Tino'
        case model.includes('Almera'):
            return 'Almera'
        case model.includes('e-NV200'):
            return 'e-NV200'
        case model.includes('NV200'):
            return 'NV200'
        case model.includes('Patrol GR'):
            return 'Patrol GR'
        case model.includes('Patrol'):
            return 'Patrol'
        case model.includes('Primastar 2021'):
            return 'Primastar 2021'
        case model.includes('Primastar'):
            return 'Primastar'
        case model.includes('Combo Life'):
            return 'Combo Life'
        case model.includes('Combo'):
            return 'Combo'
        case model.includes('Crossland X'):
            return 'Crossland X'
        case model.includes('Crossland'):
            return 'Crossland'
        case model.includes('Insignia Country Tourer'):
            return 'Insignia Country Tourer'
        case model.includes('Insignia'):
            return 'Insignia'
        case model.includes('Mokka X'):
            return 'Mokka X'
        case model.includes('Mokka'):
            return 'Mokka'
        case model.includes('Tigra Twin Top'):
            return 'Tigra Twin Top'
        case model.includes('Tigra'):
            return 'Tigra'
        case model.includes('Zafira Life'):
            return 'Zafira Life'
        case model.includes('Zafira'):
            return 'Zafira'
        case model.includes('Cayenne Coupe'):
            return 'Cayenne Coupe'
        case model.includes('Cayenne'):
            return 'Cayenne'
        case model.includes('718 Cayman'):
            return '718 Cayman'
        case model.includes('Cayman'):
            return 'Cayman'
        case model.includes('Boxster'):
            return 'Boxster'
        case model.includes('Megane Scenic'):
            return 'Megane Scenic'
        case model.includes('Megane'):
            return 'Megane'
        case model.includes('Scenic'):
            return 'Scenic'
        case model.includes('Kangoo'):
            return 'Kangoo'
        case model.includes('Rapid'):
            return 'Rapid'
        case model.includes('Ka'):
            return 'Ka'
        case model.includes('Justy G3X'):
            return 'Justy G3X'
        case model.includes('Justy'):
            return 'Justy'
        case model.includes('B9 Tribeca'):
            return 'B9 Tribeca'
        case model.includes('Tribeca'):
            return 'Tribeca'
        case model.includes('SJ Samurai'):
            return 'SJ Samurai'
        case model.includes('Samurai'):
            return 'Samurai'
        case model.includes('SX4 S-Cross'):
            return 'SX4 S-Cross'
        case model.includes('SX4'):
            return 'SX4'
        case model.includes('Avensis Verso'):
            return 'Avensis Verso'
        case model.includes('Avensis'):
            return 'Avensis'
        case model.includes('Aygo X'):
            return 'Aygo X'
        case model.includes('Aygo'):
            return 'Aygo'
        case model.includes('Corolla Verso'):
            return 'Corolla Verso'
        case model.includes('Corolla'):
            return 'Corolla'
        case model.includes('Prius+'):
            return 'Prius+'
        case model.includes('Prius'):
            return 'Prius'
        case model.includes('Proace City Verso'):
            return 'Proace City Verso'
        case model.includes('Proace'):
            return 'Proace'
        case model.includes('Verso S'):
            return 'Verso S'
        case model.includes('Verso'):
            return 'Verso'
        case model.includes('Yaris Cross'):
            return 'Yaris Cross'
        case model.includes('Yaris'):
            return 'Yaris'
        case model.includes('S60 Cross Country'):
            return 'S60 Cross Country'
        case model.includes('S60'):
            return 'S60'
        case model.includes('V40 Cross Country'):
            return 'V40 Cross Country'
        case model.includes('V40'):
            return 'V40'
        case model.includes('V60 Cross Country'):
            return 'V60 Cross Country'
        case model.includes('V60'):
            return 'V60'
        case model.includes('V70 Cross Country'):
            return 'V70 Cross Country'
        case model.includes('V70'):
            return 'V70'
        case model.includes('V90 Cross Country'):
            return 'V90 Cross Country'
        case model.includes('V90'):
            return 'V90'
        case model.includes('5 '):
            return '5'
        case model.includes('6 '):
            return '6'
        case model.includes('3 '):
            return '3'
        default:
            return null
    }
}